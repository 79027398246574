export default {
    LOW: {
        label: "Low",
        colorClass: "light-info",
    },
    MID: {
        label: "Medium",
        colorClass: "light-warning",
    },
    HIGH: {
        label: "High",
        colorClass: "light-danger",
    },
};