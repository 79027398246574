export default {
    PENDING: {
        label: "Pending",
        colorClass: "light-danger",
    },
    OPEN: {
        label: "Open",
        colorClass: "light-warning",
    },
    CLOSED: {
        label: "Closed",
        colorClass: "light-success",
    },
    // RESOLVED: {
    //     label: "Resolved",
    //     colorClass: "success",
    // },
};