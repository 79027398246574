<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        {{ ticket.id ? "Edit" : "New" }} ticket
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Ticket Details</h5>
      <form @submit.prevent="saveSupportTicket" ref="form">
        <div class="d-flex flex-row row mb-12">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-12 mb-5">
            <label for="title">Subject</label>
            <input
              id="title"
              type="text"
              class="form-control"
              v-model="ticket.title"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="category">Category</label>
            <select
              id="category"
              class="form-select"
              v-model="ticket.category"
              required
            >
              <option>Bug</option>
              <option>Feature request</option>
              <option>Need help</option>
              <option>Other</option>
            </select>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="priority">Priority</label>
            <select
              id="priority"
              class="form-select"
              v-model="ticket.priority"
              required
            >
              <option value="LOW">Low</option>
              <option value="MID">Medium</option>
              <option value="HIGH">High</option>
            </select>
          </div>
          <div class="d-flex flex-column col-12 mb-5">
            <label for="description">Description</label>
            <textarea
              id="description"
              class="form-control"
              v-model="ticket.description"
              required
            />
          </div>

          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', operation)"
        >Confirm</a
      > -->
      <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from "lodash";

import ApiService from "@/core/services/ApiService";

export default {
  emits: ["confirm", "cancel"],
  data() {
    return {
      ticket: {
        category: "BUG",
        priority: "LOW",
        title: "",
        description: "",
      },
    };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    saveSupportTicket() {
      console.log(JSON.parse(JSON.stringify(this.ticket)));

      if (this.ticket.id) {
        const config = {
          responseToast: {
            text: "Support ticket updated successfully.",
          },
        };
        ApiService.update("/tickets", this.ticket.id, this.ticket, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "Support ticket submitted successfully.",
          },
        };
        ApiService.post("/tickets", this.ticket, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(existingTicket) {
      if (existingTicket) {
        const existingTicketTmp = _.cloneDeep(existingTicket);

        if (existingTicketTmp.author && existingTicketTmp.author.id) {
          existingTicketTmp.author = existingTicketTmp.author.id;
        }

        this.ticket = existingTicketTmp;
      }
    },
  },
};
</script>